@font-face {
  font-family: "Andes";
  src: url("assets/fonts/Andes.ttf");
}

// Reset css
* {
  padding: 0;
  margin: 0;
}
html,
body {
  font-family: "Andes", sans-serif;
  font-size: 16px;
  background: #000;
}
ol,
ul {
  list-style: none;
}
img {
  vertical-align: top;
  width: 100%;
  border: none;
}
a {
  color: inherit;
  text-decoration: none;
}
button {
  border: none;
  background: none;
  cursor: pointer;
}
input {
  border: none;
}
a {
  cursor: pointer;
}

.App {
  overflow: hidden;
  .container {
    margin: 0 auto;
    overflow: hidden;
    &.wrapper {
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 24px;
    }
  }
  .page-main {
    .section-intro {
      position: relative;
      background-image: url(assets/images/img-bg-1.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 980px;
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        .page-title {
          width: 692px;
          @media only screen and (max-width: 728px) {
            width: auto;
          }
        }
        .content {
          border: 1px solid #efe200;
          font-size: 48px;
          font-weight: 5;
          line-height: 44.02px;
          text-align: center;
          background: #040309;
          border-radius: 21px;
          color: #efe200;
          padding: 10px 22px;
          @media only screen and (max-width: 580px) {
            font-size: 32px;
            line-height: 36px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 24px;
            line-height: 26px;
          }
        }
        .socials {
          margin-top: 80px;
          display: flex;
          align-items: center;
          gap: 17px;
          li {
            width: 56px;
            @media only screen and (max-width: 480px) {
              width: 45px;
            }
          }
        }
      }
    }
    .section-story {
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 168px;
        background-image: url(assets/images/img-bg-2.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 1672px;
        padding-bottom: 107px;
        @media only screen and (max-width: 780px) {
          margin-top: 80px;
          height: auto;
        }
        @media only screen and (max-width: 480px) {
          padding-bottom: 64px;
        }
        .page-title {
          width: 514px;
          @media only screen and (max-width: 568px) {
            width: auto;
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 32px;
          @media only screen and (max-width: 480px) {
            gap: 24px;
          }
          .des {
            font-size: 32px;
            font-weight: 5;
            line-height: 48px;
            text-align: center;
            color: #ffffff;
            max-width: 1180px;
            @media only screen and (max-width: 780px) {
              font-size: 24px;
              line-height: 32px;
            }
            @media only screen and (max-width: 568px) {
              font-size: 16px;
              line-height: 24px;
            }
          }
          .address {
            border: 1px solid #efe200;
            border-radius: 21px;
            padding: 18px 22px;
            max-width: 400px;
            background: #040309;
            color: #efe200;
          }
        }
        .video-section {
          margin-top: 68px;
          border: 1px solid #efe200;
          border-radius: 12px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #040309;
          @media only screen and (max-width: 480px) {
            margin-top: 24px;
          }
          video {
            padding: 14px;
            width: 682px;
            @media only screen and (max-width: 780px) {
              width: 482px;
            }
            @media only screen and (max-width: 568px) {
              width: 382px;
            }
            @media only screen and (max-width: 480px) {
              width: 300px;
            }
          }
        }
      }
    }
    .section-tokenomics {
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 61px 24px 168px;
        @media only screen and (max-width: 768px) {
          padding: 61px 24px 68px;
        }
        .page-title {
          width: 600px;
          @media only screen and (max-width: 668px) {
            width: 500px;
          }
          @media only screen and (max-width: 568px) {
            width: auto;
          }
        }
        .content {
          display: flex;
          align-items: center;
          gap: 32px;
          @media only screen and (max-width: 1440px) {
            gap: 24px;
            flex-wrap: wrap;
            justify-content: center;
          }
          .box-content {
            position: relative;
            width: 440px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 222px;
            @media only screen and (max-width: 480px) {
              width: 340px;
              height: 171px;
            }
            .img {
              position: absolute;
            }
            .des {
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: center;
              .title {
                font-size: 32px;
                font-weight: 5;
                line-height: 48px;
                text-align: center;
                color: #fff;
              }
              .val-1 {
                width: 343px;
                @media only screen and (max-width: 480px) {
                  width: 180px;
                }
              }
              .val-2 {
                width: 235px;
                @media only screen and (max-width: 480px) {
                  width: 135px;
                }
              }
              .val-3 {
                width: 105px;
                @media only screen and (max-width: 480px) {
                  width: 65px;
                }
              }
            }
          }
        }
      }
    }
    .section-university {
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 168px;
        @media only screen and (max-width: 868px) {
          padding-bottom: 80px;
        }
        @media only screen and (max-width: 648px) {
          padding-bottom: 48px;
        }
        .page-title {
          width: 783px;
          @media only screen and (max-width: 868px) {
            width: 600px;
          }
          @media only screen and (max-width: 648px) {
            width: auto;
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          gap: 32px;
          @media only screen and (max-width: 480px) {
            gap: 18px;
          }
          .marquee {
            display: flex;
            gap: 32px;
            @media only screen and (max-width: 480px) {
              gap: 18px;
            }
            img {
              height: 536px;
            }
            @media only screen and (max-width: 868px) {
              img {
                height: 436px;
              }
            }
            @media only screen and (max-width: 648px) {
              img {
                height: 336px;
              }
            }
            @media only screen and (max-width: 480px) {
              img {
                height: 236px;
              }
            }
          }
        }
      }
    }
  }
  .rfm-marquee {
    min-width: auto !important;
  }
  .page-footer {
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      padding-bottom: 32px;
      @media only screen and (max-width: 480px) {
        gap: 18px;
      }
      .page-title {
        width: 216px;
        @media only screen and (max-width: 480px) {
          width: 100px;
        }
      }
      .copyright {
        color: #fff;
        font-size: 16px;
        font-weight: 5;
        line-height: 14.67px;
        text-align: center;
      }
    }
  }
}
